import service from '@/utils/request'; // @Tags OpenApp
// @Summary 创建OpenApp
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.OpenApp true "创建OpenApp"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /openApp/createOpenApp [post]

export var createOpenApp = function createOpenApp(data) {
  return service({
    url: "/openApp/createOpenApp",
    method: 'post',
    data: data
  });
}; // @Tags OpenApp
// @Summary 删除OpenApp
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.OpenApp true "删除OpenApp"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /openApp/deleteOpenApp [delete]

export var deleteOpenApp = function deleteOpenApp(data) {
  return service({
    url: "/openApp/deleteOpenApp",
    method: 'delete',
    data: data
  });
}; // @Tags OpenApp
// @Summary 删除OpenApp
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除OpenApp"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /openApp/deleteOpenApp [delete]

export var deleteOpenAppByIds = function deleteOpenAppByIds(data) {
  return service({
    url: "/openApp/deleteOpenAppByIds",
    method: 'delete',
    data: data
  });
}; // @Tags OpenApp
// @Summary 更新OpenApp
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.OpenApp true "更新OpenApp"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /openApp/updateOpenApp [put]

export var updateOpenApp = function updateOpenApp(data) {
  return service({
    url: "/openApp/updateOpenApp",
    method: 'put',
    data: data
  });
}; // @Tags OpenApp
// @Summary 用id查询OpenApp
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.OpenApp true "用id查询OpenApp"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /openApp/findOpenApp [get]

export var findOpenApp = function findOpenApp(params) {
  return service({
    url: "/openApp/findOpenApp",
    method: 'get',
    params: params
  });
}; // @Tags OpenApp
// @Summary 分页获取OpenApp列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取OpenApp列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /openApp/getOpenAppList [get]

export var getOpenAppList = function getOpenAppList(params) {
  return service({
    url: "/app/getAppList",
    method: 'get',
    params: params
  });
};
export var getCpaInfo = function getCpaInfo(params) {
  return service({
    url: "/openApp/get_cpa_info",
    method: 'get',
    params: params
  });
};
export var setCpaInfo = function setCpaInfo(data) {
  return service({
    url: "/openApp/set_cpa_info",
    method: 'post',
    data: data
  });
};
export var updateOpenAppResource = function updateOpenAppResource(data) {
  return service({
    url: "/openApp/UpdateResourcesSwitch",
    method: 'put',
    data: data
  });
};